<template>
  <v-card>
    <v-card-title class="pt-8">
      <v-container>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isTransactionsRefreshing"
                @click="refreshTransactions()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'transactions'"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
            <v-col class="col-md-3 text-right">
              <export-button
                :title="constructExportTableTitle()"
                :sub-title="'Total Amount: Ksh ' + $options.filters.formatCurrency(transactionsSum)"
                :headers="exportTableHeaders"
                :data-endpoint="constructCompleteTransactionUrl()"
                :orientation="'landscape'"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row class="mt-0">
          <v-col class="text-center col-md-12">
            <span class="font-weight-light">Total Amount: </span>
            <span v-show="!isTransactionsLoading">Ksh {{ transactionsSum | formatCurrency }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :loading="isTransactionsLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          class="mt-2"
          rounded
        ></v-progress-linear>
        <p class="text-center mt-1">
          Loading...Please wait
        </p>
      </template>
      <template v-slot:body="{ items }">
        <tbody
          v-if="transactions.length > 0"
          :class="{ clickable: clickableRows }"
        >
          <tr
            v-for="transaction in items"
            :key="transaction.transaction_reference"
            v-on="clickableRows ? { click: () => onTransactionClick(transaction.id) } : {}"
          >
            <td>
              <span class="primary--text font-weight-medium">{{ transaction.account_number }}</span>
            </td>
            <td>
              <span class="primary--text font-weight-medium">{{ transaction.name }}</span>
            </td>
            <td>{{ transaction.phone_number }}</td>
            <td>{{ transaction.transaction_reference }}</td>
            <td>{{ transaction.amount }}</td>
            <td>{{ transaction.transaction_time | formatDate }}</td>
            <td v-show="selectedStation === 'all'">
              {{ transaction.meter_station_name }}
            </td>
            <td @click.stop>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    ma-3
                    v-bind="attrs"
                    text
                    icon
                    @click="showReceiptDialog = true; selectedTransaction = transaction"
                    v-on="on"
                  >
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiReceipt }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Receipt</span>
              </v-tooltip>
              <v-tooltip
                v-if="transaction.transferable"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    ma-3
                    v-bind="attrs"
                    text
                    icon
                    v-on="on"
                    @click="onTransferClick(transaction)"
                  >
                    <v-icon
                      v-bind="attrs"
                      size="34"
                      v-on="on"
                    >
                      {{ icons.mdiBankTransferOut }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Transfer transaction</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-show="!isTransactionsLoading">
            <td
              :colspan="headers.length"
              style="text-align: center"
            >
              No transactions found
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showReceiptDialog"
      max-width="450px"
    >
      <v-card>
        <v-card-text id="print-area">
          <h2>Payment Receipt</h2><br>
          <v-row class="mt-2">
            <v-col cols="12">
              <span class="font-weight-light">Date: </span>
              <span class="font-weight-medium">{{ today }}</span>
            </v-col>
            <v-col
              cols="12"
            >
              <span class="font-weight-light">Receipt Number: </span>
              <span class="font-weight-medium">{{ selectedTransaction.transaction_number }}</span>
            </v-col>
            <v-col cols="12">
              <span class="font-weight-light">Amount received: </span>
              <span class="font-weight-medium">{{ selectedTransactionAmountToWords }}. [Ksh {{ selectedTransaction.amount | formatCurrency }}]</span>
            </v-col>
            <v-col cols="12">
              <span class="font-weight-light">Received by: </span>
              <span class="font-weight-medium">{{ app_name }}</span>
            </v-col>
            <v-col
              cols="12"
            >
              <span class="font-weight-light">Received from: </span>
              <span class="font-weight-medium">{{ selectedTransaction.name }}</span>
            </v-col>
            <v-col
              cols="12"
            >
              <span class="font-weight-light">Account number: </span>
              <span class="font-weight-medium">{{ selectedTransaction.account_number }}</span>
            </v-col>
            <v-col cols="12">
              <span class="font-weight-light">Payment method: </span>
              <span class="font-weight-medium">Mpesa</span>
            </v-col>
            <v-col
              cols="12"
            >
              <span class="font-weight-light">Payment reference: </span>
              <span class="font-weight-medium">{{ selectedTransaction.transaction_reference }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showReceiptDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="printReceipt"
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <data-table-pagination
      :page="pagination.page"
      :page-count="pagination.pageCount"
      @page-change="onPageChange"
      @items-per-page-change="onItemsPerPageChange"
    />
    <transfer-transaction-dialog
      :show-dialog="transferTransaction.showDialog"
      :transaction-id="transferTransaction.transactionId"
      :amount="transferTransaction.amount"
      :account-number="transferTransaction.accountNumber"
      :name="transferTransaction.name"
      :transaction-reference="transferTransaction.transactionReference"
      @close="transferTransaction.showDialog = false"
      @transaction-transferred="getTransactions()"
    />
  </v-card>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { mdiBankTransferOut, mdiReceipt } from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import numberToWords from '@/mixins/numberToWords'
import printHtml from '@/mixins/printHtml'
import ExportButton from '../partials/ExportButton.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'
import TransferTransactionDialog from '@/components/dialogs/TransferTransactionDialog.vue'

export default {
  components: {
    SearchInput,
    TableFilter,
    RefreshButton,
    ExportButton,
    DataTablePagination,
    TransferTransactionDialog,
  },
  mixins: [numberToWords, printHtml],
  props: {
    transactionUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      today: moment().format('MMMM Do, YYYY'),
      app_name: process.env.VUE_APP_NAME,
      sortBy: ['transaction_time'],
      sortDesc: [true],
      search: '',
      headers: [
        {
          text: 'Account number',
          value: 'account_number',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Phone number',
          value: 'phone_number',
          sortable: false,
        },
        {
          text: 'Reference',
          value: 'transaction_reference',
          sortable: false,
        },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'transaction_time' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'users.account_number',
      searchFilters: [
        {
          text: 'Account number',
          value: 'users.account_number',
        },
        {
          text: 'Name',
          value: 'users.name',
        },
        {
          text: 'Phone number',
          value: 'mpesa_transactions.MSISDN',
        },
        {
          text: 'Reference',
          value: 'mpesa_transactions.TransID',
        },
        {
          text: 'Amount',
          value: 'mpesa_transactions.TransAmount',
        },
      ],
      exportTableHeaders: [],
      icons: {
        mdiBankTransferOut,
        mdiReceipt,
      },
      isTransactionsLoading: true,
      isTransactionsRefreshing: false,
      transactions: [],
      selectedTransaction: {},
      transactionsSum: '',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Transactions',
          disabled: true,
          to: { name: 'transactions' },
        },
      ],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      filters: {
        dates: {
          fromDate: '',
          toDate: '',
        },
      },
      defaultDatePickerDate: '',
      transferTransaction: {
        showDialog: false,
        transactionReference: '',
        transactionId: '',
        amount: 0,
        accountNumber: '',
        name: '',
      },
      showReceiptDialog: false,
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.transactionUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    exportTableTitle() {
      return `Transactions from ${this.filters.dates.fromDate} to ${this.filters.dates.toDate}`
    },
    selectedTransactionAmountToWords() {
      return this.numberToWords(parseInt(this.selectedTransaction.amount, 0) ?? 0)
    },
    selectedTransactionBalanceToWords() {
      return this.numberToWords(parseInt(this.selectedTransaction.amount, 0) ?? 0)
    },
  },
  watch: {
    refreshTable() {
      this.getTransactions()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getTransactions()
      }
    },
  },
  mounted() {
    if (this.clickableRows) {
      this.defaultDatePickerDate = 'Last 30 days'
    } else {
      this.defaultDatePickerDate = 'Last 1 year'
    }
    this.getTransactions()
    this.constructExportTableHeaders()
  },
  methods: {
    getTransactions: _.debounce(function () {
      this.isTransactionsLoading = true
      axios
        .get(this.constructCompleteTransactionUrl())
        .then(response => {
          this.transactionsSum = response.data.sum
          this.transactions = response.data.transactions.data
          this.pagination.page = response.data.transactions.current_page
          this.pagination.pageCount = response.data.transactions.last_page
          this.pagination.itemsPerPage = response.data.transactions.per_page
          this.isTransactionsLoading = false
          this.isTransactionsRefreshing = false
        })
        .catch(error => {
          this.isTransactionsLoading = false
          this.isTransactionsRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    onTransferClick(transaction) {
      this.transferTransaction.transactionId = transaction.id
      this.transferTransaction.amount = Number(transaction.amount)
      this.transferTransaction.accountNumber = transaction.account_number
      this.transferTransaction.name = transaction.name
      this.transferTransaction.transactionReference = transaction.transaction_reference
      this.transferTransaction.showDialog = true
    },
    refreshTransactions() {
      this.isTransactionsRefreshing = true
      this.getTransactions()
    },
    onTransactionClick(transactionId) {
      this.$router.push({ name: 'transaction-details', params: { id: transactionId } })
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getTransactions()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getTransactions()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    applyFilters(filters) {
      this.filters = filters
      this.getTransactions()
    },
    constructExportTableHeaders() {
      this.headers.forEach(element => {
        this.exportTableHeaders.push({ title: element.text, dataKey: element.value })
      })
    },
    constructCompleteTransactionUrl() {
      return `${this.transactionUrl}&fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`
    },
    constructExportTableTitle() {
      return `Transactions from ${this.filters.dates.fromDate} to ${this.filters.dates.toDate}`
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
