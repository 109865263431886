<template>
  <div class="readings">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isMeterTokensRefreshing"
                @click="refreshMeterTokens()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'tokens'"
                :default-date-picker-date="defaultDatePickerDate"
                @filter="applyFilters"
              />
            </v-col>
            <v-col class="col-md-3 text-right">
              <export-button
                :title="'Meter Tokens'"
                :headers="exportTableHeaders"
                :data-endpoint="constructCompleteMeterTokensUrl()"
                :orientation="'landscape'"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="meterTokens"
        :loading="isMeterReadingsLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template
          v-slot:body="{
            items,
          }"
        >
          <tbody v-if="meterTokens.length > 0">
            <tr
              v-for="token in items"
              :key="token.id"
              :class="{ clickable: clickableRows }"
              v-on="clickableRows ? { click: () => onMeterTokenClick(token.id) } : {}"
            >
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ token.account_number }}</span>
              </td>
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ token.user_name }}</span>
              </td>
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ token.meter_number }}</span>
              </td>
              <td>{{ token.token }}</td>
              <td>{{ token.units }}</td>
              <td>{{ token.amount_paid }}</td>
              <td>{{ token.created_at | formatDate }}</td>
              <td v-show="selectedStation === 'all'">
                {{ token.meter_station_name }}
              </td>
              <td @click.stop>
                <v-btn
                  small
                  depressed
                  color="primary"
                  @click="showResendMeterDialog(token.id, token.user_name)"
                >
                  <v-icon
                    left
                    size="16"
                  >
                    {{ icons.mdiSend }}
                  </v-icon>
                  Resend
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isMeterReadingsLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No meter tokens found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card>
    <confirm-dialog
      :show-dialog="showConfirmResendDialog"
      :is-agree-button-loading="isConfirmResendDialogButtonLoading"
      :agree-text="'Resend'"
      :message="resendTokenMessage"
      @cancel="showConfirmResendDialog = false"
      @agree="resendMeterToken()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mdiSend } from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import ExportButton from '@/components/partials/ExportButton.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    SearchInput,
    ConfirmDialog,
    TableFilter,
    RefreshButton,
    DataTablePagination,
    ExportButton,
  },
  props: {
    meterTokenUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showConfirmDeleteDialog: false,
    showConfirmResendDialog: false,
    isConfirmDeleteDialogButtonLoading: false,
    isConfirmResendDialogButtonLoading: false,
    toDelete: null,
    sortBy: ['created_at'],
    sortDesc: [true],
    pagination: {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    },
    search: '',
    headers: [
      {
        text: 'Account Number',
        value: 'account_number',
      },
      {
        text: 'Customer',
        value: 'user',
        sortable: false,
      },
      {
        text: 'Meter Number',
        align: 'start',
        value: 'number',
        sortable: false,
      },
      {
        text: 'Token',
        value: 'token',
        sortable: false,
      },
      {
        text: 'Units',
        value: 'units',
      },
      {
        text: 'Amount paid',
        value: 'amount_paid',
      },
      { text: 'Date purchased', value: 'created_at' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    activeSearchFilter: 'users.account_number',
    searchFilters: [
      {
        text: 'Account number',
        value: 'users.account_number',
      },
      {
        text: 'Customer',
        value: 'users.name',
      },
      {
        text: 'Meter number',
        value: 'meters.number',
      },
      {
        text: 'Units',
        value: 'meter_tokens.units',
      },
      {
        text: 'Amount paid',
        value: 'mpesa_transactions.TransAmount',
      },
    ],
    icons: {
      mdiSend,
    },
    meterReading: {},
    meterTokens: [],
    isMeterReadingsLoading: true,
    isMeterTokensRefreshing: false,
    toResend: '',
    toResendCustomerName: '',
    filters: {
      dates: {
        fromDate: '',
        toDate: '',
      },
    },
    defaultDatePickerDate: '',
  }),
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.meterTokenUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    resendTokenMessage() {
      return `Are you sure you want to resend token message to ${this.toResendCustomerName}?`
    },
    exportTableHeaders() {
      return this.headers
        .filter(header => header.value !== 'actions')
        .map(header => ({ title: header.text, dataKey: header.value }))
    },
  },
  watch: {
    refreshTable() {
      this.getMeterTokens()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getMeterTokens()
      }
    },
    selectedStation: {
      handler(newVal, oldVal) {
        if (newVal === 'all') {
          if (!this.headers.some(header => header.value === 'station')) {
            const stationHeader = {
              text: 'Station',
              value: 'station',
              sortable: false,
            }
            this.headers.push(stationHeader)
          }
        } else {
          this.headers = this.headers.filter(header => header.value !== 'station')
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.clickableRows) {
      this.defaultDatePickerDate = 'Last 30 days'
    } else {
      this.defaultDatePickerDate = 'Last 1 year'
    }
    this.getMeterTokens()
    if (!this.clickableRows) {
      this.removeFirstThreeTableHeader()
    }
  },
  methods: {
    showResendMeterDialog(id, name) {
      this.showConfirmResendDialog = true
      this.toResend = id
      this.toResendCustomerName = name
    },
    getMeterTokens: _.debounce(function () {
      this.isMeterReadingsLoading = true
      axios
        .get(this.constructCompleteMeterTokensUrl())
        .then(response => {
          this.meterTokens = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isMeterReadingsLoading = false
          this.isMeterTokensRefreshing = false
        })
        .catch(error => {
          this.isMeterReadingsLoading = false
          this.isMeterTokensRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshMeterTokens() {
      this.isMeterTokensRefreshing = true
      this.getMeterTokens()
    },
    resendMeterToken() {
      this.isConfirmResendDialogButtonLoading = true
      axios
        .post(`meter-tokens-resend/${this.toResend}`)
        .then(() => {
          this.$notification.success(`Token message has been resend to ${this.toResendCustomerName}`)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
    },
    closeAddMeterDialog() {
      this.meterReading = {}
      this.$emit('generate-token-dialog-close')
    },
    onMeterTokenClick(meterTokenId) {
      this.$router.push({ name: 'meter-tokens-details', params: { id: meterTokenId } })
    },
    removeFirstThreeTableHeader() {
      this.headers.splice(0, 3)
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getMeterTokens()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getMeterTokens()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    constructCompleteMeterTokensUrl() {
      return `${this.meterTokenUrl}&fromDate=${this.filters.dates.fromDate}&toDate=${this.filters.dates.toDate}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`
    },
    applyFilters(filters) {
      this.filters = filters
      this.getMeterTokens()
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>
